import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "../views/Home.vue";
import Dummy from "../views/Dummy.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: () => import(/* webpackChunkName: "impressum" */ "../views/Impressum.vue"),
  },
  {
    path: "/kapitel",
    component: () => import(/* webpackChunkName: "kapitel" */ "../views/Kapitel.vue"),
    meta: { edition: 2 },
    children: [
      {
        path: "",
        name: "Auflage-2",
        component: () => import(/* webpackChunkName: "auflage-2" */ "../views/auflage-2/Auflage-2.vue"),
      },
      {
        path: "kapitel-6",
        name: "Kapitel-2-6",
        component: () => import(/* webpackChunkName: "auflage-2" */ "../views/auflage-2/Kapitel-2-6.vue"),
      },
      {
        path: "kapitel-7",
        name: "Kapitel-2-7",
        component: () => import(/* webpackChunkName: "auflage-2" */ "../views/kapitel/Suchen.vue"),
      },
      {
        path: "kapitel-9",
        name: "Kapitel-2-9",
        component: () => import(/* webpackChunkName: "auflage-2" */ "../views/kapitel/Graphen.vue"),
      },
      {
        path: "kapitel-11",
        name: "Kapitel-2-11",
        component: () => import(/* webpackChunkName: "auflage-2" */ "../views/auflage-2/Kapitel-2-11.vue"),
      },
      {
        path: "kapitel-20",
        name: "Kapitel-2-20",
        component: () => import(/* webpackChunkName: "auflage-2" */ "../views/auflage-2/Kapitel-2-20.vue"),
      },
      {
        path: "auflage-1",
        meta: { edition: 1 },
        component: Dummy,
        children: [
          {
            path: "",
            name: "Auflage-1",
            component: () => import(/* webpackChunkName: "auflage-1" */ "../views/auflage-1/Auflage-1.vue"),
          },
          {
            path: "kapitel-7",
            name: "Kapitel-1-7",
            component: () => import(/* webpackChunkName: "auflage-1" */ "../views/kapitel/Suchen.vue"),
          },
          {
            path: "kapitel-9",
            name: "Kapitel-1-9",
            component: () => import(/* webpackChunkName: "auflage-1" */ "../views/kapitel/Graphen.vue"),
          },
          {
            path: "kapitel-19",
            name: "Kapitel-1-19",
            component: () => import(/* webpackChunkName: "auflage-1" */ "../views/auflage-1/Kapitel-1-19.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/autoren",
    name: "Autoren",
    component: () => import(/* webpackChunkName: "autoren" */ "../views/Autoren.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "exact-active",
  scrollBehavior() {
    return { left: 0, top: 0 };
  },
});

export default router;
