<template>
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark mb-3">
      <div class="container-fluid">
        <router-link to="/" class="navbar-brand">Fit fürs Studium - Informatik</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbar" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbar">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item active">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/kapitel" class="nav-link">Kapitel</router-link>
            </li>
            <li class="nav-item">
              <a href="https://www.rheinwerk-verlag.de/feedback/produkt/5385/" target="_blank" class="nav-link">Feedback</a>
            </li>
            <li class="nav-item">
              <router-link to="/autoren" class="nav-link">Autoren</router-link>
            </li>
          </ul>
          <ul class="navbar-nav nav nav-pills mb-2 mb-lg-0">
            <li class="nav-item">
              <a href="https://www.rheinwerk-verlag.de/fit-fuers-studium-informatik/" class="nav-link active bg-success" target="_blank"><i class="bi bi-cart2"></i> Kaufen</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </div>
</template>

<style scoped lang="scss">
  .navbar-nav.nav-pills .nav-link {
    padding-left: 1rem;
    padding-right: 1rem;
  }
</style>
