<template>
  <div class="container mb-3">
    <hr>
    <footer class="container-fluid">
      <div class="row">
        <div class="col-auto me-auto">&copy; 2017-2023 Arne Boockmeyer, Philipp Fischbeck, Stefan Neubert</div>
        <div class="col-auto"><router-link to="/impressum">Impressum / Datenschutz</router-link></div>
      </div>
    </footer>
  </div>
</template>
