<template>
  <div class="container">
    <div class="background">
      <div class="hat">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 400">
          <path d="M80.464 194.335v73.267c0 14.42 66.422 45.539 123.336 45.539 56.93 0 115.763-26.572 115.763-45.539v-73.3L216.88 246.9c-16.883 8.649-33.782 0-33.782 0z M213.424 42.13s-13.41-5.986-26.838 0L6.073 122.623s-13.427 5.97-.348 12.666l181.21 92.83s13.079 6.696 26.142 0l181.208-92.83s13.08-6.696-.331-12.666L213.423 42.13z M37.833 323.62H52.4V134.19H37.833z M45.123 279.902s-17.792 18.983-17.792 37.684c0 18.684 17.792 37.683 17.792 37.683s17.792-21.38 17.792-37.683c0-16.305-17.792-37.684-17.792-37.684"/>
        </svg>
      </div>
    </div>
    <div class="card">
      <h1>Fit fürs Studium</h1>
      <span>Boockmeyer &middot; Fischbeck &middot; Neubert</span>
    </div>
    <div class="overlay">
      <h1>Informatik</h1>
    </div>
  </div>
</template>

<style scoped lang="scss">
  @use "sass:math";

  @use "src/styles/colors";
  @import "~bootstrap/scss/functions";
  @import "~bootstrap/scss/variables";
  @import "~bootstrap/scss/mixins";

  .container {
    text-align: center;
    font-size: 30px;
    @include media-breakpoint-down(lg) { font-size: 25px; }
    @include media-breakpoint-down(md) { font-size: 20px; }
    @include media-breakpoint-down(sm) { font-size: 15px; }
    position: relative;
  }

  $background-height: 6.5em;

  .background {
    display: block;
    position: relative;
    height: $background-height;
    margin-bottom: -$background-height;

    background: repeating-linear-gradient(
        45deg,
        colors.$ffs-green 0px,
        colors.$ffs-green 3px,
        transparent 3px,
        transparent 6px
    );
    .hat {
      position: absolute;
      top: 0;
      right: 0;
      padding: 0.25rem 0.6rem;
      background-color: white;
      width: 4rem;
      path {
        fill: colors.$ffs-green;
      }
    }
  }

  $spacer: 2.6em;

  .card {
    display: inline-block;
    padding: 0.3em;
    margin: 1.5em auto;
    transform: rotate(-5deg);

    border: 3px solid colors.$ffs-violet;
    background: white;

    color: colors.$ffs-violet;

    h1 {
      margin: 0;
      margin-bottom: $spacer;

      font-weight: bold;
      font-size: 2em;
    }
  }

  $overlay-font-size: 2.8em;
  $overlay-padding-tb: 0.1em;
  $overlay-padding-lr: 0.6em;

  .overlay {
    position: absolute;
    top: $background-height - math.div($overlay-font-size, 2) - $overlay-padding-tb;

    width: 82%;
    min-width: 360px;
    background: colors.$ffs-violet;

    padding: $overlay-padding-tb $overlay-padding-lr;

    h1 {
      text-align: right;
      font-size: $overlay-font-size;
      color: white;
      margin: 0;
    }
  }
</style>
